import * as React from "react";
import styled from "@emotion/styled";
import { colors, screenSize, quicksandFont } from "../../utils/css/themes";
import { useUrlParse } from "../../utils/useUrlParse";

const Line = styled.div`
  height: 1px;
  width: 100%;
  border: solid 1px ${colors.lightGrey};
  margin: 25px;
  margin-left: 0;
`;
const ResourceSectionHeader = styled.h2`
  font-family: ${quicksandFont.bold};
  text-transform: uppercase;
  font-size: 18px;
  font-weight: bold;
  color: ${colors.darkGrey};
  @media (max-width: ${screenSize.largePhone}) {
    margin-left: 5px;
  }
`;
const StyledLink = styled.a`
  font-size: 18px;
  color: ${colors.blue};
`;

const ResourceContainer = styled.div`
  @media (max-width: ${screenSize.largePhone}) {
    align: center;
    width: 100%;
  }
`;

const LinkContainer = styled.div`
  margin-bottom: 10px;
`;
function formatLink(link: LinkItem) {
  console.log("link", link);
  if (link.linkType === "External Link") {
    if (!link.externalLink.includes("http"))
      link.externalLink = `//${link.externalLink}`;
    return <StyledLink href={link.externalLink}>{link.linkLabel}</StyledLink>;
  }
  if (link.linkType === "Internal Link") {
    return (
      <StyledLink href={useUrlParse(link.internalLink)}>
        {link.linkLabel}
      </StyledLink>
    );
  }
  if (link.linkType === "File Upload") {
    var file_url = `${link.fileUpload?.mediaItemUrl}`;
    return <StyledLink href={file_url}>{link.linkLabel}</StyledLink>;
  }
}

const getSubPage = (subpages: any, subpage_link: string) => {
  const page = subpages.filter((x: any) => x.link === subpage_link);
  return page && page[0];
};

const ResourceList = (props: Resources) => {
  const LinkList = styled.div`
    column-count: ${props.columnCount};
    padding-left: 25px;
    @media (max-width: ${screenSize.largePhone}) {
      column-count: 1;
      padding-left: 10px;
    }
  `;
  if (!props.resourceSections) {
    return (
      <div>
        <p>Resources could not be loaded</p>
      </div>
    );
  }
  return (
    <div>
      {props.resourceSections &&
        props.resourceSections.map((parent: ResourceSection) => {
          if (!parent.resourceType || parent.resourceType === "Custom Links") {
            return (
              <ResourceContainer>
                <h2>{parent.resourceSectionLabel || parent.resourceSection}</h2>
                {parent.resourceSubsection?.map(
                  (subsection: ResourceSubsection) => (
                    <div>
                      <ResourceSectionHeader>
                        {subsection.subsectionLabel}
                      </ResourceSectionHeader>
                      <LinkList>
                        {subsection.links &&
                          subsection.links.map((link: LinkItem) => (
                            <LinkContainer>{formatLink(link)}</LinkContainer>
                          ))}
                      </LinkList>
                    </div>
                  )
                )}

                {props.resourceSections.indexOf(parent) <
                  props.resourceSections.length - 1 && <Line />}
              </ResourceContainer>
            );
          } else {
            // TODO Not seeing this else version ever get used, are their ever any other resource types besides "Custom Links"
            return (
              <ResourceContainer>
                <h2>{parent.resourceSectionLabel}</h2>
                <LinkList>
                  {getSubPage(
                    props.sub_pages.nodes,
                    parent.resourceSubpage
                  ).acf.sub_pages.map((sub_page_pages: any, i: number) => (
                    <div>
                      <LinkContainer>
                        <StyledLink
                          href={useUrlParse(
                            parent.resourceSubpage +
                              (i ? sub_page_pages.title : "")
                          )}
                        >
                          {sub_page_pages.title}
                        </StyledLink>
                      </LinkContainer>
                    </div>
                  ))}
                </LinkList>

                {props.resourceSections.indexOf(parent) <
                  props.resourceSections.length - 1 && <Line />}
              </ResourceContainer>
            );
          }
        })}
    </div>
  );
};

interface Resources {
  columnCount: number;
  resourceSections: Array<ResourceSection>;
  sub_pages?: any;
}

interface ResourceSection {
  resourceSection: string;
  resourceSectionLabel: string;
  resourceSubsection: Array<ResourceSubsection>;
  resourceSubpage?: string;
  resourceType?: string;
}
interface ResourceSubsection {
  subsectionLabel: string;
  links: any;
}

interface LinkItem {
  linkType: string;
  linkLabel: string;
  internalLink: string;
  externalLink: string;
  fileUpload: { mediaItemUrl: string };
}

export default ResourceList;
