import * as React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import styled from "@emotion/styled";
import { TertiaryNav } from "../components/Helpers/TertiaryNav";
import ResourceList from "../components/Helpers/ResourceList";
import { navigate } from "gatsby";
import { isLoggedIn } from "../utils/auth";

const TitleHeader = styled.h2`
  text-align: center;
`;

const ContentDiv = styled.div`
  padding: 15px 0;
`;

const detailTemplate = (props: any) => {
  /* ============= Auth Guard START =============== */
  const [isAuthorized, setIsAuthorized] = React.useState(null);
  React.useEffect(() => {
    const fetchAuthStatus = async () => {
      const response = await isLoggedIn();
      setIsAuthorized(response);
    };

    fetchAuthStatus();
  }, [isLoggedIn, setIsAuthorized]);

  if (isAuthorized == null) {
    return <p>Authorizing...</p>;
  } else if (isAuthorized == false) {
    navigate("/resources/login");
    return null;
  }
  /* ============= Auth Guard END =============== */

  const tertiaryNav =
    props.data?.wpPost?.educatorResourceCenterSubpage?.subPages?.map(
      (page: SubPage, i: number) => ({
        page_title: page.title,
        page_slug:
          page.title != props.pageContext.default
            ? `/resources/${props.data.wpPost.slug}/${
                i > 0 ? page.title.toLowerCase() : ""
              }`
            : `/resources/${props.data.wpPost.slug}`,
      })
    );
  return (
    <Layout
      backButton={{ label: "Educator Resources", link: "/resources/" }}
      title={props.data?.wpPost.title}
    >
      <TertiaryNav
        tertiaryPages={tertiaryNav}
        DefaultPage={props.pageContext.sub_page.title}
      />
      <TitleHeader>{props.pageContext.sub_page.title}</TitleHeader>
      <ContentDiv
        dangerouslySetInnerHTML={{
          __html:
            props?.pageContext?.sub_page?.content || "Could  not load content.",
        }}
      />

      <ResourceList
        columnCount={2}
        resourceSections={props?.pageContext?.sub_page?.resourceSections}
      />
    </Layout>
  );
};
export default detailTemplate;

export const pageQuery = graphql`
  query ($id: String!) {
    wpPost(id: { eq: $id }) {
      ...resourceCenterQuery
    }
  }
`;
interface SubPage {
  title: string;
  content: string;
}
